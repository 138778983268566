import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import { QRCodeCanvas } from 'qrcode.react';

// Define API URL based on environment
const API_URL = process.env.NODE_ENV === 'development'
  ? 'https://appsev-vcx-dev-aimenu-ae-backend.azurewebsites.net'
  : '/api'; 

console.log('Environment:', process.env.NODE_ENV);

function App() {
  // State for form inputs
  const [include, setInclude] = useState('');
  const [not_include, setNotInclude] = useState('');
  
  // States for different food categories
  const [base_meat, setBaseMeat] = useState([]);
  const [cuisine, setCuisine] = useState([]);
  const [base_non_meat, setBaseNonMeat] = useState([]);
  const [flavour, setFlavour] = useState([]);
  const [season, setSeason] = useState([]);
  const [preference, setPreference] = useState([]);
  const [specials, setSpecials] = useState('');
  const [dessert, setDessert] = useState([]);
  
  // States for meal preferences
  const [meal_style, setMealStyle] = useState('');
  const [number_of_recipes, setNumberOfRecipes] = useState('');
  const [number_of_people, setNumberOfPeople] = useState('');
  const [number_of_steps, setNumberOfSteps] = useState('');
  const [number_of_ingredients, setNumberOfIngredients] = useState('');
  
  // States for UI management
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedButtons, setSelectedButtons] = useState({
    base_meat: [],
    cuisine: [],
    base_non_meat: [],
    flavour: [],
    season: [],
    preference: [],
    dessert: []
  });
  const [anyButtonSelected, setAnyButtonSelected] = useState(false);
  const [styleText, setStyleText] = useState('');
  const [buttons, setButtons] = useState([]);
  
  // States for document handling
  const [documentBlob, setDocumentBlob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pdfOpened, setPdfOpened] = useState(false);
  const [loadingDots, setLoadingDots] = useState('');
  const [recipeUrl, setRecipeUrl] = useState(null);

  const [resetTimer, setResetTimer] = useState(null);

  // Loading animation effect
  useEffect(() => {
    let interval;
    if (isLoading) {
      interval = setInterval(() => {
        setLoadingDots(dots => {
          if (dots.length >= 3) return '';
          return dots + '.';
        });
      }, 500);
    } else {
      setLoadingDots('');
    }
    return () => clearInterval(interval);
  }, [isLoading]);

  // Handle opening generated PDF document
  const openDocument = useCallback(() => {
    console.log('openDocument called', { documentBlob, pdfOpened });
    if (documentBlob && !pdfOpened) {
      try {
        const url = URL.createObjectURL(documentBlob);
        window.open(url, '_blank');
        console.log('PDF document opened in new tab');
        setPdfOpened(true);
      } catch (error) {
        console.error('Error opening PDF document:', error);
      }
    }
  }, [documentBlob, pdfOpened]);

  useEffect(() => {
    openDocument();
  }, [openDocument]);

  // Fetch available tags from API
  useEffect(() => {
    console.log("Defined Categories:", buttonCategories.map(c => c.name));
  
    fetch(`${API_URL}/get_tags`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Origin': window.location.origin
      }
    })
      .then(response => response.json())
      .then(data => {
        setButtons(data);
      })
      .catch(error => console.error('Error fetching tags:', error));
  }, []);

  // Define button categories with their properties
  const buttonCategories = [
    { color: '#FF7534', name: 'Base Meat', value: 'base_meat', setter: setBaseMeat },
    { color: '#421327', name: 'Cuisine', value: 'cuisine', setter: setCuisine },
    { color: '#284F27', name: 'Base Non-meat', value: 'base_non_meat', setter: setBaseNonMeat },
    { color: '#5F4626', name: 'Flavour', value: 'flavour', setter: setFlavour },
    { color: '#FFA699', name: 'Season', value: 'season', setter: setSeason },
    { color: '#C7AD94', name: 'Preference', value: 'preference', setter: setPreference },
    { color: '#7a7554', name: 'Dessert', value: 'dessert', setter: setDessert }
  ];

  // Define dropdown options for various selections
  const dropdownOptions = {
    'Specials': ["AI CG's MOROCCAN CHICKEN TAGINE"],
    'Meal': ['Breakfast', 'Lunch', 'Dinner', 'Afternoon Tea', 'Dessert'],
    'Number of Recipes': [1, 2, 3, 4, 5],
    'Number of People': Array.from({length: 10}, (_, i) => i + 1),
    'Number of Steps': ['1-3', '3-5', '5-8', '8-10'],
    'Number of Ingredients': ['2-4', '4-6', '6-8', '8-10']
  };

  // Handle tag selection/deselection
  const handleTagClick = (tag, category) => {
    setSelectedButtons(prev => {
      const updatedCategory = prev[category.value].includes(tag)
        ? prev[category.value].filter(t => t !== tag)
        : [...prev[category.value], tag];
      
      const newSelectedButtons = { ...prev, [category.value]: updatedCategory };
      
      const anySelected = Object.values(newSelectedButtons).some(arr => arr.length > 0);
      setAnyButtonSelected(anySelected);
      
      return newSelectedButtons;
    });
  };

  // Handle dropdown selection changes
  const handleDropdownChange = (key, value) => {
    switch(key) {
      case 'Specials': setSpecials(value); break;
      case 'Meal': setMealStyle(value); break;
      case 'Number of Recipes': setNumberOfRecipes(value); break;
      case 'Number of People': setNumberOfPeople(value); break;
      case 'Number of Steps': setNumberOfSteps(value); break;
      case 'Number of Ingredients': setNumberOfIngredients(value); break;
      default: break;
    }
  };

  // Format display values for dropdowns
  const getDisplayValue = (key, value) => {
    if (!value) return '';
    switch(key) {
      case 'Number of Recipes': return `${value} Recipe${value > 1 ? 's' : ''}`;
      case 'Number of People': return `${value} ${value > 1 ? 'People' : 'Person'}`;
      case 'Number of Steps': return `${value} Steps`;
      case 'Number of Ingredients': return `${value} Ingredients`;
      default: return value;
    }
  };

  // Handle category filter changes
  const handleCategoryChange = (category) => {
    setSelectedCategories(prev => 
      prev.includes(category) 
        ? prev.filter(c => c !== category) 
        : [...prev, category]
    );
  };

  // Handle form submission
  const handleSubmit = () => {
    setIsLoading(true);
    setDocumentBlob(null);
    setPdfOpened(false);
    const data = {
      recipe_num: number_of_recipes,
      people_num: number_of_people,
      step_num: number_of_steps,
      ingredient_num: number_of_ingredients,
      base_meat: selectedButtons.base_meat.join(', '),
      base_non_meat: selectedButtons.base_non_meat.join(', '),
      include: include,
      not_to_include: not_include,
      meal_style: meal_style,
      style: styleText, 
      cuisine: selectedButtons.cuisine.join(', '),
      flavour: selectedButtons.flavour.join(', '),
      preference: selectedButtons.preference.join(', '),
      special_recipes: specials,
      season: selectedButtons.season.join(', '),
      dessert: selectedButtons.dessert.join(', ')
    };
    
    console.log('Submitting data:', data); 
    // Send request to generate recipes
    fetch(`${API_URL}/generate_recipes`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Origin': window.location.origin
      },
      body: JSON.stringify(data),
    })
    .then(response => {
      const url = response.headers.get('X-Recipe-URL');
      setRecipeUrl(url);
      return response.blob();
    })
    .then(blob => {
      console.log('Success: PDF document received');
      setDocumentBlob(blob);
      setIsLoading(false);
    })
    .catch((error) => {
      console.error('Error:', error);
      setIsLoading(false);
    });
  };

  // Filter buttons based on selected categories
  const filteredButtons = selectedCategories.length > 0
    ? buttons.filter(button => selectedCategories.includes(button.category))
    : buttons;

  // Add clear function
  const handleReset = () => {
    if (resetTimer) {
      clearTimeout(resetTimer);
      setResetTimer(null);
    }
    // Clear all states
    setInclude('');
    setNotInclude('');
    setBaseMeat([]);
    setCuisine([]);
    setBaseNonMeat([]);
    setFlavour([]);
    setSeason([]);
    setPreference([]);
    setSpecials('');
    setMealStyle('');
    setNumberOfRecipes('');
    setNumberOfPeople('');
    setNumberOfSteps('');
    setNumberOfIngredients('');
    setSelectedCategories([]);
    setSelectedButtons({
      base_meat: [],
      cuisine: [],
      base_non_meat: [],
      flavour: [],
      season: [],
      preference: [],
      dessert: []
    });
    setAnyButtonSelected(false);
    setStyleText('');
    setRecipeUrl(null);
    setDocumentBlob(null);
    setPdfOpened(false);
    setDessert([]);
  };

  // Reset timer
  const startResetTimer = useCallback(() => {
    if (resetTimer) {
      clearTimeout(resetTimer);
    }
    const timer = setTimeout(() => {
      handleReset();
    }, 60000);
    setResetTimer(timer);
  }, [resetTimer]);

  // Handle input changes
  const handleInputChange = (setter, value) => {
    startResetTimer();
    setter(value);
  };

  // Render the UI
  return (
    <div className="App">
      <div className="header">
        <img src={require('./images/TMP_LOGO_BLACK.png')} alt="Market Pavilion Logo" className="logo" />
        <h1>AI MENU PLANNING COMPANION</h1>
      </div>
      <div className="content-wrapper">
        <div className="left-section">
          <div className="dropdowns-row">
            <div className="custom-select">
              <select 
                onChange={(e) => {
                  startResetTimer();
                  handleDropdownChange('Number of Recipes', e.target.value);
                }} 
                value={number_of_recipes}
              >
                <option value="">Number of Recipes</option>
                {dropdownOptions['Number of Recipes'].map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
              <span className="selected-value">
                {number_of_recipes ? getDisplayValue('Number of Recipes', number_of_recipes) : 'Number of Recipes'}
              </span>
            </div>
            <div className="custom-select">
              <select 
                onChange={(e) => {
                  startResetTimer();
                  handleDropdownChange('Number of People', e.target.value);
                }} 
                value={number_of_people}
              >
                <option value="">Number of People</option>
                {dropdownOptions['Number of People'].map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
              <span className="selected-value">
                {number_of_people ? getDisplayValue('Number of People', number_of_people) : 'Number of People'}
              </span>
            </div>
            <div className="custom-select">
              <select 
                onChange={(e) => {
                  startResetTimer();
                  handleDropdownChange('Number of Steps', e.target.value);
                }} 
                value={number_of_steps}
              >
                <option value="">Number of Steps</option>
                {dropdownOptions['Number of Steps'].map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
              <span className="selected-value">
                {number_of_steps ? getDisplayValue('Number of Steps', number_of_steps) : 'Number of Steps'}
              </span>
            </div>
          </div>
          <div className="dropdowns-row">
            <div className="custom-select">
              <select 
                onChange={(e) => {
                  startResetTimer();
                  handleDropdownChange('Number of Ingredients', e.target.value);
                }} 
                value={number_of_ingredients}
              >
                <option value="">Number of Ingredients</option>
                {dropdownOptions['Number of Ingredients'].map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
              <span className="selected-value">
                {number_of_ingredients ? getDisplayValue('Number of Ingredients', number_of_ingredients) : 'Number of Ingredients'}
              </span>
            </div>
            <div className="custom-select">
              <select 
                onChange={(e) => {
                  startResetTimer();
                  handleDropdownChange('Meal', e.target.value);
                }} 
                value={meal_style}
              >
                <option value="">{meal_style || 'Meal'}</option>
                {dropdownOptions['Meal'].map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
              <span className="selected-value">
                {meal_style ? getDisplayValue('Meal', meal_style) : 'Meal'}
              </span>
            </div>
            <select name="Specials" onChange={(e) => handleDropdownChange('Specials', e.target.value)} value={specials}>
              <option value="">{specials || "Chadstone's Special Recipes"}</option>
              {dropdownOptions['Specials'].map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>
          <div className="popular-categories-label">
            Popular Categories (Optional) – choose none, one or multiple
          </div>
          <div className="button-grid">
            {filteredButtons.map((button, index) => {
              if (!button.name || !button.category) {
                console.warn(`found invalid button data:`, button);
                return null;
              }
              const category = buttonCategories.find(c => c.name === button.category);
              if (!category) {
                console.warn(`category not found: ${button.category} for button: ${button.name}`);
                return null;
              }
              const isSelected = selectedButtons[category.value].includes(button.name);
              return (
                <button
                  key={index}
                  className={`category-button ${category.value} ${isSelected ? 'selected' : ''} ${anyButtonSelected && !isSelected ? 'faded' : ''}`}
                  onClick={() => {
                    startResetTimer();
                    handleTagClick(button.name, category);
                  }}
                  style={{
                    backgroundColor: category.color,
                    opacity: anyButtonSelected && !isSelected ? 0.7 : 1
                  }}
                >
                  {button.name}
                </button>
              );
            })}
          </div>
        </div>
        
        <div className="right-section">
          <div className="input-column">
            <div className="input-group">
              <label>
                I'd love to include (enter anything)
                <span className="input-example">E.g. tomato, pasta, chicken, celery, chilli</span>
              </label>
              <input
                type="text"
                value={include}
                onChange={(e) => handleInputChange(setInclude, e.target.value)}
              />
            </div>
            <div className="input-group">
              <label>
                I'd prefer not to have (enter anything)
                <span className="input-example">E.g. olives, onion, fish, avocado, mushroom</span>
              </label>
              <input
                type="text"
                value={not_include}
                onChange={(e) => handleInputChange(setNotInclude, e.target.value)}
              />
            </div>
            <div className="input-group">
              <label>
                Style (enter anything)
                <span className="input-example">E.g. Asian, Italian, gluten free, low FODMAP</span>
              </label>
              <input
                type="text"
                value={styleText}
                onChange={(e) => handleInputChange(setStyleText, e.target.value)}
              />
            </div>
          </div>
          
          <div className="right-bottom">
            <div className="categories-filter">
              <h3>Categories</h3>
              {buttonCategories.map((category, index) => (
                <label key={index} className={`category-label ${category.value}`}>
                  <input 
                    type="checkbox" 
                    checked={selectedCategories.includes(category.name)}
                    onChange={() => handleCategoryChange(category.name)}
                  />
                  {category.name}
                </label>
              ))}
            </div>
            <div className="submit-button-container">
              <button 
                className="reset-button" 
                onClick={handleReset}
              >
                Reset
              </button>
              
              {!recipeUrl ? (
                <button 
                  className="submit-button" 
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? `Generating${loadingDots}` : 'Submit'}
                </button>
              ) : (
                <div className="qr-code-container">
                  <QRCodeCanvas value={recipeUrl} size={128} />
                  <p>Scan QR code to get recipe</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <img src={require('./images/CHADSTONE_WORDMARK_BLACK.png')} alt="Chadstone Logo" className="chadstone-logo" />
      </div>
    </div>
  );
}

export default App;
